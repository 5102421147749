// @flow

const styles = (theme: Object) => ({
  root: {
    margin: '0 auto',
    width: 400,
    marginTop: '10vh'
  },
  formCtr: {
    display: 'flex',
    flexDirection: 'column',
    '& *': {
      marginBottom: theme.spacing(0.5)
    }
  }
});

export default styles;