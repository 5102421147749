import React from 'react';
import {
  withRouter
} from "react-router-dom";

import { log } from './utils/jsUtils';
import { useStore } from './store';
import useUserSession from './hooks/userProfile';
import {Typography} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/ProtectedHeader.Style';

const AuthButton = (props) => {
  const { classes } = props;
  useUserSession();
  // log('[AuthButton] userSession: ', userSession);
  const [store, dispatch] = useStore();
  const {
    isAuthenticated,
    email
  } = store;
  log('[AuthButton] isAuthenticated: ', isAuthenticated);

  const handleSignout = () => {
    log('[AuthButton] handle Signout');
    dispatch({
      type: 'ACTION_SET_USER_SESSION',
      isAuthenticated: false,
      email: ''
    })
  }
  
  return (
    <div>
      {
        isAuthenticated
        ? <Typography variant={'body1'} className={classes.headerLink}>
            <a onClick={handleSignout}>{'Sign out'}</a>
          </Typography>
        : null
      }
    </div>
  )
};

export default withStyles(styles)(AuthButton);
