// @flow

import React, { useEffect } from 'react';

import ProtectedHeader from './ProtectedHeader';
import Container from '@material-ui/core/Container';
import {
  Link
} from "react-router-dom";
import Login from './Login';
import Search from './Search';
import { useStore } from './store';

const AppLayout = () => {
  const [store, dispatch] = useStore();
  const {
    isAuthenticated,
    email
  } = store;
  return (
    <div>
      <ProtectedHeader />

      {
        !isAuthenticated || !email
        ? <Login />
        : <Search />
      }
  </div>
  )
}

export default AppLayout;
