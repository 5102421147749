import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { FhirResource, fhirVersions } from './utils/fhir-react/src';
import oncallBackend from './services/oncallBackend';
import classnames from 'classnames';
import {log} from './utils/jsUtils';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { withStyles } from '@material-ui/core/styles';

import styles from './Styles/PatientChart.Style';

import "fhir-react/build/style.css";
import "fhir-react/build/bootstrap-reboot.min.css";

const resourcesSupported = [
  {
    code: 'Patient',
    label: 'Patient'
  }, {
    code: 'Coverage',
    label: 'Coverage'
  }, {
    code: 'AllergyIntolerance',
    label: 'Allergies'
  }, {
    code: 'CarePlan',
    label: 'Care Plan'
  }, {
    code: 'DiagnosticReport',
    label: 'Diagnostic Report'
  }, {
    code: 'Medication',
    label: 'Medications'
  }, {
    code: 'DocumentReference',
    label: 'Documents'
  }, {
    code: 'Immunization',
    label: 'Immunization'
  }, {
    code: 'Procedure',
    label: 'Procedures'
  }, {
    code: 'Encounter',
    label: 'Encounters'
  }, {
    code: 'Observation',
    label: 'Observation'
  }, {
    code: 'Procedure',
    label: 'Procedure'
  }, {
    code: 'QuestionnaireResponse',
    label: 'Questionnaire Responses'
  }, {
    code: 'MedicationStatement',
    label: 'Medication Statement'
  }, {
    code: 'MedicationDispense',
    label: 'Medication Dispense'
  }, {
    code: 'MedicationRequest',
    label: 'Medication Request'
  }
]

const PatientChart = (props) => {
  const { classes, patient, patientFhir, setSelectedPatient } = props;
  
  const [fhirResources, setFhirResources] = useState([]);
  // const container = window !== undefined ? () => window().document.body : undefined;
  
  const selectResource = (resource) => (e) => {
    if (e) { e.stopPropagation(); }
    log('[selectResource] resource: ', resource, patientFhir.entry);
    if (typeof patientFhir !== 'undefined' && patientFhir.entry) {
      const hasResource = entry => R.propEq('resourceType', resource)(entry.resource);
      const test = R.filter(hasResource, patientFhir.entry);
      log('test finding patientFhir resource: ', test);
      setFhirResources(test);
    }
  }

  useEffect(() => {
    if (patientFhir) {
      log('[useEffect] select default', patientFhir);
      selectResource(resourcesSupported[0].code)();
    }
  }, [patientFhir])

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.appBar}
        color='secondary'
        position="absolute"
      >
        <Toolbar >
        <IconButton
          onClick={() => setSelectedPatient(null)}>
          <ArrowBackIosIcon fontSize='small'/>
        </IconButton>
          <Typography variant="h6" noWrap>
          {patient.resource.name[0].text}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
        <div>
          <div className={classes.toolbar} />
          <List disablePadding>
          {
            resourcesSupported.map((r, rI) => (
              <ListItem
                button
                onClick={selectResource(r.code)}
                key={`${r.code}-${rI}`}>
                <ListItemText primary={r.label} />
              </ListItem>
            ))
          }
          </List>
          </div>
        </Drawer>
      </nav>
      <main className={classes.content}>
        {
          fhirResources && fhirResources.length > 0
          ? fhirResources.map((fhirResource, idx) => (
            <FhirResource
              key={`resource-${idx}`}
              fhirResource={fhirResource.resource}
              fhirVersion={fhirVersions.R4}
              thorough={false}
            />
          ))
          : <div>No data available.</div>
        }
      </main>
    </div>
  )
}

export default withStyles(styles)(PatientChart);
