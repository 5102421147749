// @flow

import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import oncallBackend from './services/oncallBackend';
import { useStore } from './store';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useUserSession from './hooks/userProfile';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/Login.Style';

const Login = (props) => {
  const { classes } = props;
  // const userSession = useUserSession();
  // log('[Login] userSession: ', userSession);
  const { register, handleSubmit, watch, errors } = useForm();

  const [errorMessage, setErrorMessage] = useState('');
  const [store, dispatch] = useStore();
  // const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const {
    isAuthenticated
  } = store;

  const handleLogin = (data) => {
    const { email, password } = data;
    oncallBackend.authenticate({ email, password })
      .then((response) => {
        dispatch({
          type: 'ACTION_SET_USER_SESSION',
          isAuthenticated: true,
          email
        })
      })
      .catch((error) => {
        setErrorMessage('Invalid credentials');
        dispatch({
          type: 'ACTION_SET_USER_SESSION',
          isAuthenticated: false,
          email: ''
        })
      });
  };

  return (
    <div>
    {
      isAuthenticated
      ? <Redirect to='/' />
      : <Container maxWidth="md">
        <div className={classes.root}>
          <p>Login with OnCallMD Credentials </p>
          <form onSubmit={handleSubmit(handleLogin)} className={classes.formCtr}>
            <TextField variant="outlined" name='email' inputRef={register}></TextField>
            <TextField variant="outlined" type='password' name='password' inputRef={register}></TextField>
            {
              errorMessage !== ''
              ? <p>{errorMessage}</p>
              : null
            }
            <Button variant="contained" color="primary" type='submit'>Log in</Button>
          </form>
        </div>
      </Container>
    }
    </div>
  );
}

export default withStyles(styles)(Login);
