// @flow

import { log } from '../utils/jsUtils';

import type { State } from '../store/initialState';

const reducers = (state: State, action: Object) => {
  log('[reducer]: ', action);
  log(`[reducer] ${action.type}`, state, action);

  switch (action.type) {
    case 'ACTION_SET_USER_SESSION':
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        email: action.email
      };
    default:
      log('[reducer] default error. action: ', action);
      throw new Error();
  }
};

export default reducers;