import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { StoreProvider } from './store';
import initialState from './store/initialState';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import AppLayout from './AppLayout';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import Search from './Search';
// import {log} from './utils/jsUtils';


const App = () => {
  useEffect(() => {
    setCorrectViewportHeight();
  }, []);
  const setCorrectViewportHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    if (document.documentElement) {
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  };

  return (
    <StoreProvider initialValue={initialState}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path="/" component={AppLayout}/>
            <PrivateRoute exact path="/search" component={Search}/>
            <PrivateRoute exact path="/chart" component={Search}/>
            <Route exact path="/login" component={Login}/>
          </Switch>
        </Router>
        
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
