// @flow

const styles = (theme: Object) => ({
  paperRoot: {
    marginTop: theme.spacing(4)
  },
  header: {
  },
  searchInputRoot: {
    // borderBottom: '1px solid #8A8A8A',
    '& label': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(2)
    },
    '& input': {
      // paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: '1.1rem'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(0.75) translateY(8px)'
    },
    '& .MuiInputAdornment-positionStart': {
      marginLeft: theme.spacing(1)
    }
  },
  table: {
    // minHeight: 400
  },
  patientRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEE'
    }
  },
  searchResult: {
    padding: theme.spacing(2),
    minHeight: 400
  },
  infoSecondary: {
    width: 150,
    fontSize: '0.95rem',
    fontWeight: 300,
    textTransform: 'capitalize'
  },
  loading: {
    height: 64,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  }
});

export default styles;