// @flow

import axios from 'axios';
import {log} from '../utils/jsUtils';

const serverURL = process.env.REACT_APP_ONCALLMD_SERVER_URL;

const publicAxios = (url = '') => {
  const baseUrl = url !== '' ? url : serverURL;
  // Create instance
  const defaultOptions = {
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return axios.create(defaultOptions);
};

// const authenticatedAxios = (user, baseURL = serverURL) => {
//   return getNewIdToken(user)
//     .then((freshIdToken: ?string) => {
//       // Create instance
//       const defaultOptions = {
//         baseURL: baseURL,
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       };
//       let instance = axios.create(defaultOptions);
//       // Set the AUTH token for any request
//       log('[SPBackend.authenticatedAxios] freshIdToken: ', freshIdToken);
//       instance.defaults.headers.common['Authorization'] = `Bearer ${freshIdToken}`;
//       return instance;
//     });
// };

const authenticate = (payload: { email: string, password: string }): Promise<Object> => {
  const _publicAxios = publicAxios(serverURL);
  return _publicAxios.post('/chartapi/v1/auth/sign-in', payload);
}

const searchPatient = (search: {firstName: string, lastName: string}): Promise<Object> => {
  log('[searchPatient] search: ', search);
  const _publicAxios = publicAxios(serverURL);
  return _publicAxios.post('/chartapi/v1/patient/fullSearch', search);
}

const searchPatientByName = (name: string): Promise<Object> => {
  const _publicAxios = publicAxios(serverURL);
  return _publicAxios.post('/chartapi/v1/patient', {
    name
  });
}

const getPatientById = (id: string): Promise<Object> => {
  const _publicAxios = publicAxios(serverURL);
  return _publicAxios.get(`/chartapi/v1/patient/${id}/$everything`);
}

const getVisits = (): Promise<Object> => {
  const _publicAxios = publicAxios(serverURL);
  return _publicAxios.get('/admin/visits');
}

export default {
  authenticate,
  getVisits,
  searchPatientByName,
  searchPatient,
  getPatientById
};
