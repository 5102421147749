// @flow

import { createMuiTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';

const headerHeight = 90;

const theme:Object = createMuiTheme();

theme.palette = {
  ...theme.palette,
  background: {
    paper: '#fff',
    default: '#fafafa'
  },
  primary: {
    lighter: '#bbdefb',
    light: '#64b5f6',
    main: '#2196f3',
    dark: '#1b77c0',
    contrastText: '#fff'
  },
  secondary: {
    light: teal[300],
    main: teal[500],
    dark: teal[700]
  }
};

theme.layout = {
  responsiveScreenHeight: 'var(--vh, 1vh) * 100',
};

theme.mdportals = {
  toolbar: {
    height: headerHeight,
    minHeight: headerHeight,
    [theme.breakpoints.down('sm')]: {
      minHeight: headerHeight + theme.spacing(1)
    }
  }
};

theme.typography = {
  ...theme.typography,
  root: {
    fontSize: '1.05rem',
    opacity: 1,
    // color: '#FFFFFF',
    fontFamily: ['Allumi Std', 'sans-serif'].join(',')
  },
  h1: {
    ...theme.typography.h1,
    fontSize: '1.6rem',
    opacity: 1,
    fontWeight: 800,
    letterSpacing: '0.6px',
    fontFamily: ['Allumi Std Extended', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.3rem'
    }
  },
  h2: {
    ...theme.typography.h2,
    fontSize: '2.5rem',
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    fontFamily: ['Allumi Std', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem'
    }
  },
  h3: {
    ...theme.typography.h3,
    fontSize: '2.2rem',
    fontWeight: 700,
    fontFamily: ['Allumi Std', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem'
    }
  },
  h4: {
    ...theme.typography.h4,
    fontSize: '1.8rem',
    fontWeight: 700,
    fontFamily: ['Allumi Std', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem'
    }
  },
  h5: {
    ...theme.typography.h5,
    fontSize: '1.5rem',
    fontFamily: ['Allumi Std', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem'
    }
  },
  h6: {
    ...theme.typography.h6,
    fontSize: '0.98rem',
    fontFamily: ['Allumi Std', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap'
  },
  body1: {
    // color: '#FFFFFF',
    fontSize: '1.05rem',
    fontWeight: 400,
    opacity: 1,
    fontFamily: ['Allumi Std', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap'
  },
  body2: {
    // color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '1.05rem',
    fontFamily: ['Allumi Std', 'sans-serif'].join(','),
    whiteSpace: 'normal',
  },
  button: {
    fontSize: '1.05rem',
    fontFamily: ['Allumi Std', 'sans-serif'].join(','),
    fontWeight: 700
  },
  pre: {
    fontFamily: ['Allumi Std', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap'
  }
};

theme.overrides = {
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: '#363636'
    },
    colorSecondary: {
      backgroundColor: 'transparent',
      color: 'black'
    }
  },
  // MuiTableCell: {
  //   root: {

  //   }
  MuiTableRow: {
    root: {
      verticalAlign: 'top'
    }
  }
};

export default theme;