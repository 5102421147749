// @flow

import React, { useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './assets/MDPortals-logo-square.png';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/ProtectedHeader.Style';
import AuthButton from './AuthButton';

const ProtectedHeader = (props) => {
  const { classes } = props;
  return (
    <AppBar position="static">
      <Toolbar classes={{
        root: classes.toolbarRoot
      }}>
        <Container maxWidth="md">
          <div className={classes.headerCtr}>
            <img src={Logo} height={35} />
            <Typography variant='h1'>Patient Record Lookup</Typography>
            <AuthButton />
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  )
};

export default withStyles(styles)(ProtectedHeader);
