import React, { useState, useEffect } from 'react';
import oncallBackend from './services/oncallBackend';
import classnames from 'classnames';
import {log} from './utils/jsUtils';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

import SearchIcon from '@material-ui/icons/Search';

import PatientChart from './PatientChart';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/Search.Style';


const Search = (props) => {
  const { classes } = props;
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientFhir, setSelectedPatientFhir] = useState(null);
  const [isLoadingPatient, setIsLoadingPatient] = useState(false);
  const [search, setSearch] = useState({
    firstName: '',
    lastName: ''
  });

  const searchPatient = (newSearch) => {
    oncallBackend.searchPatient(newSearch)
      .then((response) => {
        log('[Search][searchPatientByName] response: ', response.data);
        const { data } = response;
        const { entry } = data.patients;
        setPatients(entry);
        log('[Search][searchPatientByName] patients: ', entry);
      })
      .catch((error) => {
        log('[Search][searchPatientByName] error: ', error);
      })
  }

  const handleSearchPatientByLastName = (e) => {
    const newSearch = {
      ...search,
      lastName: e.target.value
    };
    setSearch(newSearch);
    searchPatient(newSearch);
  }

  const handleSearchPatientByFirstName = (e) => {
    const newSearch = {
      ...search,
      firstName: e.target.value
    };
    setSearch(newSearch);
    searchPatient(newSearch);
  }

  const handleSelectedPatient = (patient) => (e) => {
    log('[handleSelectedPatient] patient: ', patient);
    setSelectedPatient(patient);
    setSelectedPatientFhir(null);
    setIsLoadingPatient(true);
    oncallBackend.getPatientById(patient.resource.id)
      .then((response) => {
        log('[handleSelectedPatient][getPatientById] response: ', response.data);
        setSelectedPatientFhir(response.data.patient)
        setIsLoadingPatient(false);
        // const fhir = new Fhir();
        // var obj = fhir.jsonToObj(response.data);
      })
      .catch((error) => {
        setIsLoadingPatient(false);
        log('[handleSelectedPatient][getPatientById] error: ', error);
      })
  }

  return (
    <Container maxWidth="md">
      <Paper classes={{
        root: classes.paperRoot
      }}>
        {
          selectedPatient && isLoadingPatient
          ? <Paper className={classes.loading}>
            <p>Loading...</p>
          </Paper>
          : selectedPatient && selectedPatientFhir && selectedPatientFhir.entry && selectedPatientFhir.entry.length > 0
            ? <PatientChart
                patient={selectedPatient}
                patientFhir={selectedPatientFhir}
                setSelectedPatient={setSelectedPatient}
                />
            : <>
              <div className={classes.header}>
                <TextField 
                  classes={{
                    root: classnames(
                      classes.searchInputRoot
                    )
                  }}
                  fullWidth={true}
                  id="firstName"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                  }}
                  placeholder="First Name"
                  label="Search patient by first name"
                  onChange={handleSearchPatientByFirstName}
                  value={search.firstName} />
                <TextField 
                  classes={{
                    root: classes.searchInputRoot
                  }}
                  fullWidth={true}
                  id="lastName"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                  }}
                  placeholder="Last Name"
                  label="and by last name"
                  onChange={handleSearchPatientByLastName}
                  value={search.lastName} />
              </div>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  
                {
                  patients && patients.length > 0
                  ? patients.map((patient, patientIdx) => (
                      patient.resource.name
                      ? <TableRow
                          className={classes.patientRow}
                          onClick={handleSelectedPatient(patient)}
                          key={`${patient.resource.name[0].text}}-${patientIdx}`}>
                          <TableCell component="th" scope="row">
                            {patient.resource.name[0].text}
                          </TableCell>
                          <TableCell
                            align='right'
                            className={classes.infoSecondary}
                          >
                            {patient.resource.gender}
                          </TableCell>
                          <TableCell
                            align='right'
                            className={classes.infoSecondary}
                          >
                            {patient.resource.birthDate}
                          </TableCell>
                        </TableRow>
                      : null
                    ))
                  : null
              }
              </TableBody>
            </Table>
            </>
        }
      </Paper>
    </Container>
  );
}

export default withStyles(styles)(Search);
