// @flow

const styles = (theme: Object) => ({
  toolbarRoot: {
    ...theme.mdportals.toolbar
  },
  headerCtr: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerLink: {
    fontSize: '0.9rem'
  }
});

export default styles;