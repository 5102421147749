// @flow

import React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";
import { useStore } from './store';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [store, dispatch] = useStore();
  const {
    isAuthenticated
  } = store;

  return (
    <Route {...rest} render={(props) => (
      // call /auth/sign-request here to test authentication.
      isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )
};

export default PrivateRoute;
