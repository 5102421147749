// @flow
import { useState, useEffect } from 'react';
import { useStore } from '../store';
import { log } from '../utils/jsUtils';

function useUserSession() {
  const [store, dispatch] = useStore();
  const {
    isAuthenticated,
    email
  } = store;

  log('[userProfile][render] isAuth: ', localStorage.getItem('mdp-isAuth'));
  log('[userProfile][render] store.isAuth: ', isAuthenticated);
  log('[userProfile][render] store.email: ', email);

  const initialSession = () => ({
    isAuthenticated: localStorage.getItem('mdp-isAuth') === 'true' ? true : false,
    email: localStorage.getItem('mdp-em'),
  })
    
  useEffect(() => {
    log('[userProfile][] isAuth: ', localStorage.getItem('mdp-isAuth'));
    log('[userProfile.[]] initialSession(): ', initialSession());
    // load session from storage
    // setUserSession(initialSession());
    // if authenticated, update the store
    dispatch({
      type: 'ACTION_SET_USER_SESSION',
      ...initialSession()
    });
  }, []) // run once

  useEffect(() => {
    if (email !== null && isAuthenticated !== null) {
      log('[userProfile hook] isAuthenticated, email: ', isAuthenticated, email);
      localStorage.setItem('mdp-isAuth', isAuthenticated ? isAuthenticated.toString() : '');
      localStorage.setItem('mdp-em', email || '');
      // setUserSession({
      //   isAuthenticated,
      //   email
      // });
    }
  }, [isAuthenticated, email])

  // return userSession;
}

export default useUserSession;
