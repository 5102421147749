import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import CDAStylesheet from '../xmlStylesheet/CDA.xsl';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [xmlData, setXmlData] = useState();

  function toggle() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle,
    xmlData,
    setXmlData,
  };
};

const Modal = ({ isShowing, hide, xmlData }) => {
  const xmlRef = useRef(null);

  function loadXMLDoc(filename) {
    let xhttp = new XMLHttpRequest();

    xhttp.open('GET', filename, false);
    try {
      xhttp.responseType = 'msxml-document';
    } catch (err) {}
    xhttp.send('');
    return xhttp.responseXML;
  }

  function formatXMLDoc(xmlDoc) {
    let xml = loadXMLDoc(xmlDoc);
    let xsl = loadXMLDoc(CDAStylesheet);

    let xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsl);
    let resultDocument = xsltProcessor.transformToFragment(xml, document);

    return resultDocument;
  }

  useEffect(() => {
    if (xmlData && xmlRef.current) {
      const formattedXML = formatXMLDoc(xmlData);
      xmlRef.current.appendChild(formattedXML);
    }
  }, [isShowing]);

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div ref={xmlRef} className="modal-xml-container"></div>
            </div>
          </div>
        </React.Fragment>,
        document.body,
      )
    : null;
};

export { useModal, Modal };
