// @flow

const drawerWidth = 200;

const styles = (theme: Object) => ({
  root: {
    display: 'flex',
    position: 'relative',
    height: `calc(var(--vh, 1vh) * 100 - ${theme.mdportals.toolbar.height}px - 120px)`
  },
  drawer: {
    marginTop: 64,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    boxShadow: '0px 1px 2px -1px rgba(0,0,0,0.2), 0px 2px 1px 0px rgba(0,1,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)',
    backgroundColor: 'white',
    zIndex: 9999,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%)`,
      marginLeft: drawerWidth,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    zIndex: 1,
    width: drawerWidth,
    position: 'absolute'
  },
  content: {
    marginTop: 64,
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowY: 'scroll'
  },
  margin: {
    padding: theme.spacing(1),
  }
});

export default styles;