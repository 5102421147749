// @flow

export type State = {
  isAuthenticated: ?bool,
  email: ?string
  
}

const initialState: State = {
  isAuthenticated: null,
  email: null
};
export default initialState;